import {TRAIN_CREATE, TRAIN_DELETE, TRAIN_LIST, TRAIN_UPDATE} from "../../actions/rail/train";
import TrainDataSvc from '../../../services/rail/TrainDataSvc'

const state = {
  train: []
};

const getters = {
  getTrain: state => state.train,
};

const actions = {
  [TRAIN_LIST]: ({ commit }) => {
    return new Promise((resolve) => {
      TrainDataSvc.getAll()
        .then(response => {
          commit(TRAIN_LIST, response.data);
          resolve(response);
        })
      })
    },
  [TRAIN_CREATE]: ({ dispatch }, train )=> {
    return new Promise((resolve) => {
      TrainDataSvc.create(train)
        .then(response => {
          dispatch(TRAIN_LIST);
          resolve(response);
        })
      })
    },
  [TRAIN_DELETE]: ({ dispatch }, train )=> {
    return new Promise((resolve) => {
      TrainDataSvc.delete(train)
        .then(response => {
          dispatch(TRAIN_LIST);
          resolve(response);
        })
    })
  },
  [TRAIN_UPDATE]: ({ dispatch }, train )=> {
    return new Promise((resolve) => {
      TrainDataSvc.update(train.id,train)
        .then(response => {
          dispatch(TRAIN_LIST);
          resolve(response);
        })
    })
  },
};

const mutations = {
  [TRAIN_LIST]: (state, resp) => {
    state.train = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
