<template>

      <v-list nav dense>
       <div v-for="(link, i) in links" :key="i">

        <v-list-item
            v-if="!link.subLinks"
            :to="link.to"
            color="white darken-2"
            avatar
            class="v-list-item"
        >
            <v-list-item-icon>
                <v-icon >{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="link.text" />
        </v-list-item>

        <v-list-group
            v-else
            :key="link.text"
            no-action
            :prepend-icon="link.icon"
            :value="false"
            color="white darken-2"
        >
            <template v-slot:activator>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
             </template>

            <v-list-item
                v-for="sublink in link.subLinks"
                :to="sublink.to"
                :key="sublink.text"
            >
                <v-list-item-title>{{ sublink.text }}</v-list-item-title>

            </v-list-item>

        </v-list-group>

    </div>

      </v-list>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([ 'isAdmin' ])
    },
  data: () => ({
links: [
    {
        icon   : 'mdi-server',
        text   : 'Kontenery',
        ashow  : false,
        subLinks : [
            {
                text : 'Na placu',
                to    : '/kontenery/kontenery',
            },
            {
                text : 'Archiwum',
                to    : '/kontenery/konteneryout',
            },
            {
                text : 'Awizacje',
                to    : '/kontenery/awizacje',
            },
        ]
    },
    {
      icon: 'mdi-train',
      text: 'Kolej',
      ashow: false,
      subLinks: [
        {
          text: 'Pociągi',
          to: '/rail/train',
        },
      ]
    },
    {
        icon     : 'mdi-tools',
        text     : 'Remonty',
        ashow  : false,
        subLinks : [
            {
                text : 'Box-y',
                to    : '/remonty/dmgbox',
            },
            {
                text : 'PTI i agregaty',
                to    : '/remonty/dmgreefer',
            },
            {
                text : 'Naprawy całość',
                to    : '/remonty/dmgall',
            },
            {
                text : 'Karty napraw',
                to    : '/remonty/karty',
            },
            {
                text : 'Katalogi napraw',
                to    : '/remonty/katalogi',
            },
            {
                text : 'Usługi zewnętrzne',
                to    : '/remonty/servicesout',
            },
        ]
    },
    {
        icon     : 'mdi-wrench-cog-outline',
        text     : 'Usługi',
        ashow  : false,
        subLinks : [
            {
                text : 'Na placu',
                to    : '/services/onplace',
            },
            {
                text : 'Zewnętrzne',
                to    : '/services/outside',
            },
        ]
    },
    {
        to     : '/kontrahenci',
        icon   : 'mdi-domain',
        text   : 'Kontrahenci',
        ashow  : false,
    },
    {
        icon     : 'mdi-currency-eur',
        text     : 'Rozliczenia',
        ashow  : false,
        subLinks : [
            {
                text : 'Faktury',
                to    : '/rozliczenia/faktury',
            },
            {
                text : 'Korekty',
                to    : '/rozliczenia/korekty',
            },
            {
                text : 'Noty księgowe',
                to    : '/rozliczenia/noty',
            },
            {
                text : 'Faktury ProForma',
                to    : '/rozliczenia/proforma',
            },
            {
                text : 'Kursy walut',
                to    : '/rozliczenia/kursywalut',
            },
            {
                text : 'Konta bankowe',
                to    : '/rozliczenia/bankaccounts',
            },
            {
                text : 'Towar/usługi',
                to    : '/rozliczenia/products',
            },
            {
                text : 'Uwagi do faktur',
                to    : '/rozliczenia/invcomments',
            },
            {
                text : 'Powody korekty',
                to    : '/rozliczenia/reasons',
            },
        ]
    },
    {
        icon   : 'mdi-cash-multiple',
        text   : 'Stawki',
        ashow  : false,
        subLinks : [
            {
                text : 'Cenniki usług',
                to   : '/tariff/tariff'
            },
            {
                text : 'Usługi',
                to   : '/tariff/tariffservices'
            },
            {
                text : 'Handling',
                to   : '/rates/rateshandling'
            },
            {
                text : 'Składowe',
                to   : '/rates/ratesstorage'
            },
        ]
    },
    {
        icon   : 'mdi-format-list-bulleted',
        text   : 'Słowniki',
        ashow  : true,
        subLinks : [
            {
                text : 'Typy kontenerów',
                to    : '/slowniki/conttype',
            },
            {
                text : 'Rozmiary kontenerów',
                to    : '/slowniki/contsize',
            },
            {
                text : 'Klasy kontenerów',
                to    : '/slowniki/contclass',
            },
            {
                text : 'Statusy kontenerów',
                to    : '/slowniki/contstatus',
            },
            {
                text : 'Przeznaczenie',
                to    : '/slowniki/dcontassignment',
            },
            {
                text : 'Blokady',
                to    : '/slowniki/dcontblockreason',
            },
            {
                text : 'Rodzaje PTI',
                to    : '/slowniki/dcontpti',
            },
            {
                text : 'Lokalizacje i place',
                to    : '/slowniki/location',
            },
            {
                text : 'Naprawy',
                to    : '/slowniki/repairs',
            },
            {
                text : 'Jednostki miary',
                to    : '/slowniki/units',
            },
            {
                text : 'Waluty',
                to    : '/slowniki/currency',
            },
        ]
    },
    {
        to     : '/raporty',
        icon   : 'mdi-file-chart',
        text   : 'Raporty',
        ashow  : false,
    },
    {
        icon   : 'mdi-key-change',
        text   : 'Uprawnienia',
        ashow  : true,
        subLinks : [
            {
                text : 'Role',
                to    : '/users/Role',
            },
            {
                text : 'Użytkownicy',
                to    : '/users/Users',
            },
        ]
    },
    {
        to     : '/settings',
        icon   : 'mdi-cog-outline',
        text   : 'Ustawienia',
        ashow  : true,
    },
]
  })
}
</script>

<style scoped>
.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 24px;
  margin-bottom: 0;
}
.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item .v-list-item__title {
  margin-left: 10px;
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 12px;
}
</style>